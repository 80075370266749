import { v4 as uuidv4 } from 'uuid';

export const parseGetAllChats = allMessages => {
	const chatArray = [
		{
			id: uuidv4(),
			sender: 'GPT',
			message: "I'm ready to answer your questions, Ask me Anything! I will answer in the context of document selected."
		}
	];

	allMessages.map(obj => {
		const you_obj = { ...obj, id: uuidv4(), sender: 'You', message: obj.question };
		const gpt_obj = { ...obj, id: uuidv4(), sender: 'GPT', message: obj.answer };
		return chatArray.push(you_obj, gpt_obj);
	});

	return { success: true, response: chatArray };
};

export const parseCollectionData = collectionData => {
	if (!Array.isArray(collectionData) || collectionData.length === 0) return [];

	const parsedData = collectionData.map(eachCollection => {
		return {
			id: eachCollection?.collection_id,
			collection_name: eachCollection?.collection_name,
			display_name: eachCollection?.collection_name,
			knowledge_base_path: eachCollection?.knowledge_base_path,
			uploaded_by: eachCollection?.uploaded_by,
			is_collection_doc: true
		};
	});

	return parsedData;
};

export const parseCollectionDoc = collectionDocData => {
	if (!Array.isArray(collectionDocData) || collectionDocData.length === 0) return [];

	const parsedData = collectionDocData.map(eachDoc => {
		return {
			id: eachDoc?.docs_id,
			name: eachDoc?.file_name,
			file_path: process.env.REACT_APP_API_URL + eachDoc?.file_path,
			collection_id: eachDoc?.collection_id
		};
	});

	return parsedData;
};
