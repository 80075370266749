export const colors = {
	white: '#F1F1F1',
	black: '#000000',
	grayBgColor: '#DCDCDC',
	gray_100: '#e8e6e6',
	gray_200: '#EFEFEF',
	gray_300: '#D0D5DD',
	gray_500: '#667085',
	gray_700: '#344054',
	gray_900: '#101828',
	grayDark: '#949494',
	grayBorder: '#B5B5B5',
	grayLight: '#F2F2F2',
	primary: '#ffffff',
	borderColor: '#C2C2C2',
	hoverBgColor: '#1e293a',
	itemSelected: '#FEC92B',
	selectedNavColor: '#0F6EC7',
	navColor: '#162D42',
	faqCardBgColor: '#2E3033',
	faqCardBorder: '#ABABAD',
	chatProfileCardBg: '#596F4C',
	sideNavBGcolor: '#1677ff',
	sideNavBGcolorHighlighted: '#003f96',
	sideNavBarLogo: '#151B54',
	chatScreenBackground: '#EDEEF8',
	logOut: '#F75D59',
	noDocColor: '#1e293a',
	sendButton: '#151B54',
	deleteIcon: '#fff'
};
