import React from 'react';
import styles from './CustomButton.module.scss';

function CustomButton({ title, onClick, style, type, tooltipText }) {
	return (
		<button className={styles.custom_button} onClick={onClick} style={style} type={type} title={tooltipText}>
			{title}
		</button>
	);
}

export default CustomButton;
