import {
	Alert,
	Box,
	CircularProgress,
	IconButton,
	InputBase,
	Modal,
	Stack,
	Typography,
	useMediaQuery
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { UserSessionContext } from '../../context/UserSessionContext';
import { ApiContext } from '../../context/ApiContext';
import { colors } from '../../asset/colors';
import CustomButton from '../CustomButton/Custombutton';
import FileUploadDropzone from './FileUploadDropzone';

const DOC_UPLOAD_LIMIT = 4;

const CreateEditCollectionModal = ({ open, onClose, handleSubmit, isEdit = false, selectedCollection }) => {
	const isSmallerScreen = useMediaQuery('(max-width:900px)');

	const { authenticatedUser } = useContext(UserSessionContext);
	const { createCollection, getAllCollectionDocuments, removeDocFromCollection, addDocsToCollection } =
		useContext(ApiContext);

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [fileUploadErrMsg, setFileUploadErrMsg] = useState(false);
	const [collectionName, setCollectionName] = useState('');
	const [showFileLoader, setShowFileLoader] = useState(false);

	const userId = authenticatedUser?.username;

	useEffect(() => {
		if (isEdit && selectedCollection) {
			const getCollectionDocData = async () => {
				setShowFileLoader(true);
				const { success, response } = await getAllCollectionDocuments(selectedCollection?.id);

				if (success) {
					setSelectedFiles(response);
				}

				setShowFileLoader(false);
			};

			setCollectionName(selectedCollection?.collection_name);
			getCollectionDocData();
		}
	}, [getAllCollectionDocuments, isEdit, selectedCollection]);

	const handleFileChange = e => {
		const files = e?.dataTransfer?.files || e?.target?.files;

		const filesArray = Array.from(files);

		const combinedFiles = [...filesArray, ...selectedFiles];

		if (combinedFiles.length > DOC_UPLOAD_LIMIT) {
			setFileUploadErrMsg(`Cannot upload more than ${DOC_UPLOAD_LIMIT} document`);
			return;
		}

		if (selectedFiles.some(eachSelectedFile => filesArray.some(file => file.name === eachSelectedFile.name))) {
			setFileUploadErrMsg('Cannot deploy same document twice');
			return;
		}

		setFileUploadErrMsg('');
		setSelectedFiles(combinedFiles);
	};

	const handleFileRemove = async fileToRemove => {
		if (isEdit && fileToRemove?.id) {
			if (selectedFiles?.length > 1) {
				const { response } = await removeDocFromCollection(fileToRemove.id);
				if (response?.response_status !== 200) {
					return alert('Something went wrong while removing docs from collection !');
				}
			} else {
				return alert(
					'Cannot Delete the PDF as your collection have only 1 PDF! If you still want to delete you have to delete this collection'
				);
			}
		}

		const filteredFiles = selectedFiles.filter(eachFile => eachFile.name !== fileToRemove.name);

		setSelectedFiles(filteredFiles);
	};

	const handleSubmitData = async () => {
		if (!selectedFiles?.length) {
			setFileUploadErrMsg('Need at least one document in a collection');
			return;
		}

		if (!collectionName) {
			setFileUploadErrMsg('Collection name cannot be empty');
			return;
		}

		onClose();

		if (isEdit) {
			const filesToUpdate = selectedFiles.filter(eachFile => !eachFile?.id);

			if (filesToUpdate?.length > 0) {
				await addDocsToCollection(userId, selectedCollection?.id, filesToUpdate);
			}
		} else {
			await createCollection(userId, collectionName, selectedFiles);
		}

		resetState();

		handleSubmit && handleSubmit();
	};

	const resetState = () => {
		setSelectedFiles([]);
		setCollectionName('');
	};

	const handleModalClose = () => {
		resetState();
		onClose();
	};

	const handleOnFilePress = file => {
		let filePath = file.id ? file.file_path : URL.createObjectURL(file);
		window.open(filePath, '_blank');
	};

	return (
		<Modal open={open} onClose={handleModalClose}>
			<Box sx={[styles.modalContainer, { width: isSmallerScreen ? '75%' : '35%' }]}>
				<IconButton onClick={handleModalClose} sx={styles.modalCloseIcon}>
					<CloseIcon sx={styles.closeIconStyle} fontSize="small" />
				</IconButton>

				<InputBase
					value={collectionName || ''}
					onChange={e => setCollectionName(e.target.value)}
					placeholder="Collection Name"
					name="collectionName"
					type={'text'}
					required
					disabled={isEdit}
					sx={styles.collectionNameInput}
					inputProps={{
						style: { color: colors.black }
					}}
				/>

				{showFileLoader ? (
					<Box sx={styles.fileLoader}>
						<CircularProgress />
					</Box>
				) : (
					<Stack py={3} sx={selectedFiles?.length > 0 && styles.fileContentContainer}>
						{selectedFiles.length > 0 &&
							selectedFiles.map((eachFiles, index) => {
								return (
									<Box key={index} sx={styles.fileContainer} onClick={() => handleOnFilePress(eachFiles)}>
										<IconButton
											onClick={e => {
												if (e && e.stopPropagation) e.stopPropagation();
												handleFileRemove(eachFiles);
											}}
											sx={styles.closeIconContainer}
										>
											<CloseIcon sx={{ color: colors.black }} fontSize="large" />
										</IconButton>

										<Typography sx={styles.fileName}>{eachFiles?.name}</Typography>
									</Box>
								);
							})}

						{selectedFiles.length < 4 && (
							<FileUploadDropzone
								handleDragEnter={e => e.preventDefault()}
								handleDragOver={e => e.preventDefault()}
								handleDragLeave={e => e.preventDefault()}
								handleDrop={handleFileChange}
								sendMultipleFiles={true}
								containerStyle={{ width: '100%' }}
								uploadBoxStyle={styles.dropZoneStyle}
							/>
						)}
					</Stack>
				)}

				<CustomButton title="Submit" style={styles.submitBtn} onClick={handleSubmitData} />

				{fileUploadErrMsg && (
					<Alert sx={{ marginTop: 3 }} severity="error" color="error">
						{fileUploadErrMsg}
					</Alert>
				)}
			</Box>
		</Modal>
	);
};

const styles = {
	modalContainer: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: colors.white,
		boxShadow: 24,
		p: 3,
		borderRadius: 5
	},
	fileContentContainer: {
		height: '200px',
		width: '100%',
		my: 1,
		overflowY: 'scroll',
		overflowX: 'hidden',
		'& > *+*': {
			/* hide scrollbar for IE, Edge and Firefox */
			msOverflowStyle: 'none'
		},
		'::-webkit-scrollbar': {
			/* hide scrollbar for chrome, safari and opera */
			display: 'none'
		}
	},
	fileContainer: {
		position: 'relative',
		cursor: 'pointer',
		border: '1.5px dashed #B5B5B5',
		borderWidth: 'thin',
		borderRadius: '10px',
		fontSize: '1rem',
		backgroundColor: colors.grayBgColor,
		'&:hover': {
			backgroundColor: colors.gray_300
		},
		minHeight: 75,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		mb: 1
	},
	cardIcon: {
		cursor: 'pointer',
		width: '2rem',
		margin: '10px auto'
	},
	closeIcon: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		mb: 2
	},
	fileLoader: {
		m: 2,
		minHeight: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	closeIconContainer: {
		position: 'absolute',
		right: 0
	},
	closeIconStyle: {
		color: colors.white,
		borderRadius: 10,
		backgroundColor: colors.black
	},
	fileName: {
		width: '90%',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	collectionNameInput: {
		backgroundColor: colors.primary,
		border: 0.5,
		borderColor: colors.borderColor,
		width: '100%',
		height: '45px',
		borderRadius: 3,
		padding: '1rem',
		marginTop: '23px'
	},
	modalCloseIcon: {
		position: 'absolute',
		right: 8,
		top: 8
	},
	dropZoneStyle: {
		backgroundColor: colors.gray_100,
		flex: 1,
		minHeight: 75,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	submitBtn: {
		width: '100%',
		margin: 0,
		marginBottom: '15px',
		fontSize: '12px'
	}
};

export default CreateEditCollectionModal;
