import React, { useContext } from 'react';
import { RingLoader } from 'react-spinners';
import styles from './loaderHoc.module.css';
import { UserSessionContext } from '../../context/UserSessionContext';

const LoaderHoc = ({ children }) => {
	const { showLoader } = useContext(UserSessionContext);

	return (
		<>
			{children}
			{showLoader && (
				<div className={styles.loaderOverlay}>
					<RingLoader color="#ffffff" size={60} />
				</div>
			)}
		</>
	);
};

export default LoaderHoc;
