import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import { CustomButton, CustomInput, SignupScreenWrapper } from '../../components';
import styles from './LogIn.module.css';
import ErrorAlert from '../../components/ErrorAlert';
import logoImage from '../../asset/images/magure_icon.webp';

const LogIn = () => {
	const { loginUser, getCurrentAuthenticatedUser } = useContext(AmplifyAuthContext);
	const navigate = useNavigate();

	const [errorOpen, setErrorOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleShowError = message => {
		setErrorMessage(message);
		setErrorOpen(true);
	};

	const handleCloseError = () => {
		setErrorOpen(false);
	};

	const handleSignIn = async event => {
		event.preventDefault();
		const { email, password } = event.target.elements;

		try {
			const { success, errorMsg } = await loginUser(email.value, password.value);

			if (!success) {
				handleShowError(errorMsg);
			} else {
				await getCurrentAuthenticatedUser();
				navigate('/', { replace: true });
			}
		} catch (error) {
			handleShowError('An error occurred');
		}
	};

	return (
		<div className="login-screen">
			<div class="image-container">
				<div class="text-container">
					<img src={logoImage} alt="Logo" class="logo" width="100" height="100" />
					<div>
						<h1>Chat with Docs</h1>
						<p>
						Unlock the power of AI to effortlessly analyze your documents. Interact with your papers, reports, and textbooks like never before. Revolutionize your document experience with unparalleled ease.
						</p>
					</div>
				</div>
			</div>

			<div className="form-container">
				<div className="header">
					<p className="text">Welcome Back!</p>
				</div>

				<form className="form" onSubmit={handleSignIn}>
					<h2>Log In</h2>

					<CustomInput
						placeholder="john@gmail.com"
						inputTitle="Email"
						name="email"
						type={'text'}
						className={styles.formItems}
						required
					/>

					<CustomInput
						placeholder="********"
						inputTitle="Password"
						name="password"
						type={'password'}
						className={styles.formItems}
						required
					/>

					<div className={styles.createAccountLink}>
						<Link to="/signup">Don't have an account? Sign up now.</Link>
					</div>

					<CustomButton title="Submit" type={'submit'} style={{ width: '100%' }} />
					<ErrorAlert open={errorOpen} message={errorMessage} onClose={handleCloseError} />
				</form>
			</div>
		</div>
	);
};

export default LogIn;
