import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const ConfirmationAlert = ({ open, label, message, onConfirm, onCancel, isCancelVisible = true, btnText="Confirm" }) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{label}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      {isCancelVisible && (<Button onClick={onCancel}>Cancel</Button>)}
      <Button onClick={onConfirm} color="error">
        {btnText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationAlert;
