import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import MuiAppBar from '@mui/material/AppBar';

export const drawerWidth = 300; //in px
export const minimisedDrawer = 4; //in rem

export const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));


export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export const dummyChatData = [
  {
    id: uuidv4(),
    sender: "GPT",
    message:
      "I'm ready to answer your questions, Ask me Anything! I will answer in the context of document selected.",
  },
];

export const navListoptions = [
  'View',
  'Delete File',
  'Delete Chat History'
];

export const navList = {
  view: 'View',
  deleteFile: 'Delete File',
  deleteChatHistory: 'Delete Chat History',
}