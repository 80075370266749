import { Amplify } from 'aws-amplify';
import AmplifyAuthContextProvider, { AmplifyAuthContext } from './context/AmplifyAuthContext';
import { AwsConfigAuth } from './config/auth';
import Routes from './Routes';
import UserSessionContextProvider from './context/UserSessionContext';
import '@sendbird/uikit-react/dist/index.css';
import { useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ApiContextProvider from './context/ApiContext';
import LoaderHoc from './components/hoc/LoaderHoc';

Amplify.configure(AwsConfigAuth);

const theme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#3f51b5'
		},
		secondary: {
			main: '#f50057'
		}
	}
});

function AppWrap() {
	return (
		<ThemeProvider theme={theme}>
			<UserSessionContextProvider>
				<ApiContextProvider>
					<AmplifyAuthContextProvider>
						<LoaderHoc>
							<App />
						</LoaderHoc>
					</AmplifyAuthContextProvider>
				</ApiContextProvider>
			</UserSessionContextProvider>
		</ThemeProvider>
	);
}

function App() {
	const { getCurrentAuthenticatedUser } = useContext(AmplifyAuthContext);

	useEffect(() => {
		(async () => {
			await getCurrentAuthenticatedUser();
		})();
	}, [getCurrentAuthenticatedUser]);

	return <Routes />;
}

export default AppWrap;
