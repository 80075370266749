import React, { useContext, useState } from 'react';
import styles from './SideNavDrawer.module.scss';
import { useNavigate } from 'react-router-dom';

import { Box, Drawer, IconButton, Typography } from '@mui/material';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';

import NavHeader from './NavHeader/NavHeader';
import NavBarListItems from './NavBarListItems/NavBarListItems';
import { UserSessionContext } from '../../context/UserSessionContext';
import ConfirmationAlert from './ConfirmationAlert';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import { AppBar, drawerWidth } from '../../constant/Constant';
import { getTruncatedText } from '../../utils/utils';
import { MinimisedToolBar } from './MinimizedToolBar/MinimizedToolBar';

export default function SideNavDrawer({
	window,
	documentsData,
	collectionDocsData,
	handleSelectedList,
	selectedFileID,
	setSelectedFileID,
	onLogout,
	isResponsiveWindow,
	setIsResponsiveWindow,
	onFileUpload,
	handleCreateCollection
}) {
	const navigate = useNavigate();
	const { authenticatedUser } = useContext(UserSessionContext);
	const { signOutUser } = useContext(AmplifyAuthContext);
	const [showConfirmLogout, setShowConfirmLogout] = useState(false);

	const container = window !== undefined ? () => window().document.body : undefined;

	const handleDrawerToggle = () => {
		setIsResponsiveWindow();
	};
	const handleLogout = async () => {
		await signOutUser();
		navigate('/login');
	};

	const getNameFromEmail = userEmail => {
		const atIndex = userEmail.indexOf('@');
		const name = atIndex ? userEmail.slice(0, atIndex) : '';
		return getTruncatedText(name, 28);
	};

	return (
		<>
			<AppBar
				elevation={6}
				position="fixed"
				className={styles.customAppbar}
				sx={{
					width: { md: `calc(100% - ${drawerWidth}rem)` },
					ml: { sm: `${drawerWidth}rem` }
				}}
			>
				<MinimisedToolBar handleDrawerToggle={() => handleDrawerToggle()} onLogout={() => setShowConfirmLogout(true)} />
			</AppBar>
			<Drawer
				container={container}
				variant="persistent"
				open={isResponsiveWindow}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true // Better open performance on mobile.
				}}
				sx={{
					display: { md: 'block', lg: 'block' },
					overflow: 'hidden',
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
				}}
				className={styles.drawerContainer}
			>
				<Box
					sx={{ width: { sm: drawerWidth, lg: '100%' }, overflow: 'hidden' }}
					className={styles.navContainer}
					aria-label="mailbox folders"
				>
					<NavHeader handleDrawerToggle={handleDrawerToggle} />

					<NavBarListItems
						documentsData={documentsData}
						collectionDocsData={collectionDocsData}
						selectedFileID={selectedFileID}
						setSelectedFileID={setSelectedFileID}
						onFileUpload={onFileUpload}
						onListItemClick={(item, hideDrawer) => {
							if (hideDrawer) handleDrawerToggle();
							handleSelectedList(item, hideDrawer);
						}}
						handleCreateCollection={handleCreateCollection}
					/>

					{/* Sign Out */}
					{authenticatedUser && (
						<Box className={styles.logoutContainer}>
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<AccountCircleRoundedIcon sx={{ fontSize: '35px', marginRight: '5px' }} />
								<Box>
									<Typography className={styles.userName} sx={{ fontWeight: 'bold' }}>
										{getNameFromEmail(authenticatedUser?.attributes?.email)}
									</Typography>
									<Typography className={styles.userName} title={authenticatedUser?.attributes?.email}>
										{getTruncatedText(authenticatedUser?.attributes?.email, 28)}
									</Typography>
								</Box>
							</Box>
							<IconButton
								sx={{ boxShadow: 1, height: '30px', width: '30px' }}
								onClick={() => setShowConfirmLogout(true)}
							>
								<PowerSettingsNew sx={{ fontSize: 20 }} className={styles.logoutButton} />
							</IconButton>
						</Box>
					)}
					<ConfirmationAlert
						open={showConfirmLogout}
						label={'Log Out'}
						message="Are you sure you want to log out?"
						onConfirm={() => handleLogout()}
						onCancel={() => setShowConfirmLogout(false)}
					/>
				</Box>
			</Drawer>
		</>
	);
}
