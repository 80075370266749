import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomButton, CustomInput, SignupScreenWrapper } from '../../components';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import styles from './SignUp.module.css';
import ErrorAlert from '../../components/ErrorAlert';

function SignUp() {
  const { signUpUser } = useContext(AmplifyAuthContext);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleShowError = (message) => {
    setErrorMessage(message);
    setErrorOpen(true);
  };

  const handleCloseError = () => {
    setErrorOpen(false);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    const { email, password } = event.target.elements;

    try {
      const { success, errorMsg, response } = await signUpUser(email.value, password.value);

      if (!success) {
        handleShowError(errorMsg);
      } else {
        const otpDeliveryDestination = response.codeDeliveryDetails.Destination;

        navigate('/otp', {
          state: {
            email: email.value,
            otpDeliveryDestination,
          },
        });
      }
    } catch (error) {
      handleShowError('An error occurred');
    }
  };

  return (
    <SignupScreenWrapper>
      <form onSubmit={handleSignUp}>
        <h2>Sign Up</h2>
        <CustomInput placeholder="John Doe" inputTitle="Name" name="userName" type={'text'} className={styles.formItems} required />

        <CustomInput placeholder="john@gmail.com" inputTitle="Email" name="email" type={'text'} className={styles.formItems} required />

        <CustomInput placeholder="********" inputTitle="Password" name="password" type={'password'} className={styles.formItems} required />

        <div className={styles.loginLink}>
          <Link to={'/login'}>Already Registered? Log In.</Link>
        </div>

        <br />

        <CustomButton title="Submit" type={'submit'} style={{ width: '100%' }} />
      </form>
      <ErrorAlert open={errorOpen} message={errorMessage} onClose={handleCloseError} />
    </SignupScreenWrapper>
  );
}

export default SignUp;
