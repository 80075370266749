import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import ConfirmationAlert from '../../components/SideNavDrawer/ConfirmationAlert';
import SideNavDrawer from '../../components/SideNavDrawer/SideNavDrawer';
import { UserSessionContext } from '../../context/UserSessionContext';
import { Grid } from '@mui/material';
import { TalkToGpt } from '../../components';
import { drawerWidth, minimisedDrawer } from '../../constant/Constant';
import PDFDocument from '../../components/PDFDoc/PDFDocument';
import { ApiContext } from '../../context/ApiContext';
import { colors } from '../../asset/colors';
import CreateEditCollectionModal from '../../components/SideNavDrawer/CreateEditCollectionModal';

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	marginLeft: `${minimisedDrawer + 0.3}em`,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		// marginLeft: `${drawerWidth+100}px`,
		marginLeft: `${drawerWidth}px`
	})
}));

export default function Home1() {
	const navigate = useNavigate();

	const { signOutUser } = useContext(AmplifyAuthContext);
	const { getDocumentByUserId, getAllCollectionByUserId } = useContext(ApiContext);

	const [showConfirmLogout, setShowConfirmLogout] = useState(false);

	const { setSelectedFile, authenticatedUser } = useContext(UserSessionContext);
	const userID = authenticatedUser?.username;
	const baseURL = process.env.REACT_APP_API_URL;
	const [pdfUrl, setPdfUrl] = useState(null);
	const [selectedPDF, setSelectedPDF] = useState(null);
	const [isResponsiveWindow, setIsResponsiveWindow] = useState(!pdfUrl ? true : false);
	const [documentsData, setDocumentsData] = useState([]);
	const [collectionDocsData, setCollectionDocsData] = useState([]);

	const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] = useState(false);

	const handleSelectedList = (item, hideDrawer) => {
		setSelectedPDF(item);
		const docId = item.id;

		if (docId && !item?.is_collection_doc) {
			const docUrl = `${baseURL + item.file_path}`;
			setPdfUrl(docUrl);
			setIsResponsiveWindow(false);
		}

		return setSelectedFile(item);
	};

	const getAllDocData = useCallback(async () => {
		setPdfUrl(null);
		setSelectedFile(null);
		const documentRes = await getDocumentByUserId(userID);

		if (documentRes.success) {
			const data = documentRes.response?.response_data;
			setDocumentsData(data);
			if (data.length) {
				setSelectedFile(data[data.length - 1]);
				handleSelectedList(data[data.length - 1]);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getDocumentByUserId, setSelectedFile, userID]);

	useEffect(() => {
		setSelectedFile(null);
		getAllDocData();
	}, [getAllDocData, setSelectedFile]);

	const getCollectionData = useCallback(async () => {
		const { success, response } = await getAllCollectionByUserId(userID);
		if (success) {
			setCollectionDocsData(response);
			if (response.length > 0) {
				setSelectedFile(response?.[0]);
				setSelectedPDF(response?.[0]);
			} else getAllDocData();
		}
	}, [getAllCollectionByUserId, getAllDocData, setSelectedFile, userID]);

	useEffect(() => {
		getCollectionData();
	}, [getCollectionData]);

	const handleDrawerToggle = () => {
		setIsResponsiveWindow(!isResponsiveWindow);
	};
	const handleLogout = async () => {
		await signOutUser();
		navigate('/login');
	};

	const getFileName = () => {
		if (selectedPDF || selectedPDF?.file_name) {
			return selectedPDF.file_name?.split('_').join(' ');
		}
		return '';
	};

	const renderRightContent = () => {
		if (selectedPDF?.is_collection_doc) {
			return (
				<TalkToGpt
					onDeleteFile={() => getCollectionData()}
					customHeaderTitle={selectedPDF?.collection_name}
					customDeleteText={'Delete Collection'}
					handleDrawerToggle={handleDrawerToggle}
				/>
			);
		}

		return (
			<>
				<Grid
					item
					xs={0}
					md={pdfUrl ? 5 : 0}
					sx={[
						{ display: { xs: 'none', sm: pdfUrl ? 'block' : 'none' } },
						{ position: 'relative' },
						{ display: 'flex', justifyContent: 'center', alignItems: 'center' }
					]}
				>
					{pdfUrl && <PDFDocument url={pdfUrl} fileName={getFileName()} />}
				</Grid>
				<Grid
					item={false}
					xs={12}
					md={pdfUrl ? 7 : 12}
					sx={{ borderLeft: pdfUrl ? 1 : 0, borderColor: colors.gray_300 }}
				>
					<TalkToGpt
						onDeleteFile={() => getAllDocData()}
						customDeleteText="Delete PDF"
						handleDrawerToggle={handleDrawerToggle}
					/>
				</Grid>
			</>
		);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<SideNavDrawer
				isResponsiveWindow={isResponsiveWindow}
				documentsData={documentsData}
				collectionDocsData={collectionDocsData}
				setIsResponsiveWindow={handleDrawerToggle}
				handleSelectedList={(item, hideDrawer) => handleSelectedList(item, hideDrawer)}
				onFileUpload={() => getAllDocData()}
				handleCreateCollection={() => setIsCreateCollectionModalOpen(true)}
			/>

			<Main open={isResponsiveWindow} sx={{ flexGrow: 1, p: 0, border: 0 }}>
				<Grid
					container
					sx={{
						height: '100vh',
						overflow: 'hidden',
						border: 0,
						flexWrap: 'nowrap',
						pt: { sm: 0, md: 0 },
						pl: { sm: 1, md: 0 },
						m: 0
					}}
				>
					{renderRightContent()}
				</Grid>
			</Main>

			<ConfirmationAlert
				open={showConfirmLogout}
				label={'Log Out'}
				message="Are you sure you want to log out?"
				onConfirm={() => handleLogout()}
				onCancel={() => setShowConfirmLogout(false)}
			/>

			<CreateEditCollectionModal
				open={isCreateCollectionModalOpen}
				onClose={() => setIsCreateCollectionModalOpen(false)}
				handleSubmit={getCollectionData}
			/>
		</Box>
	);
}
