import { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { SearchIcon, searchPlugin } from '@react-pdf-viewer/search';
import { Box, Button, Popper, Typography } from '@mui/material';
import CustomizeSearchControl from './CustomizeSearchControl';
import { ClickAwayListener } from '@material-ui/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

export default function PDFDocument({ url, fileName }) {
	const zoomPluginInstance = zoomPlugin();
	const { ZoomPopover } = zoomPluginInstance;
	const pageNavigationPluginInstance = pageNavigationPlugin();
	const { CurrentPageLabel, NumberOfPages } = pageNavigationPluginInstance;
	const zoomValue = SpecialZoomLevel.PageFit;
	const searchPluginInstance = searchPlugin();
	const { clearHighlights } = searchPluginInstance;
	const [openSearch, setOpenSearch] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const handleSearchButtonClick = event => {
		setOpenSearch(!openSearch);
		setAnchorEl(event.currentTarget);
		clearHighlights();
	};

	const handleCloseSearch = () => {
		setOpenSearch(false);
		clearHighlights();
	};

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
			<div
				style={{
					alignItems: 'center',
					backgroundColor: '#F2F2F2',
					display: 'flex',
					justifyContent: 'center',
					padding: '0.4em 0.6em 0.4em 1em',
					boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
				}}
			>
				<Box sx={{ mr: 'auto' }}>
					<Typography>{fileName}</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontFamily: 'Arial ,Roboto ,Helvetica,sans-serif',
						fontSize: '0.85em'
					}}
				>
					<Box mr={0.7} ml={0.7}>
						<CurrentPageLabel />/<NumberOfPages />
					</Box>
					<Box>
						<ZoomPopover />
					</Box>
					<Box>
						<Button onClick={handleSearchButtonClick} style={{ minWidth: '20px' }}>
							<SearchIcon />
						</Button>
					</Box>
					{/* <IconButton  onClick={handleReset}>
                <ReplayIcon color="dark" size={24} />
                </IconButton> */}
				</Box>
			</div>
			<Viewer
				fileUrl={url}
				plugins={[bookmarkPlugin, zoomPluginInstance, pageNavigationPluginInstance, searchPluginInstance]}
				ScrollMode="Page"
				defaultScale={zoomValue}
			/>
			<Popper
				open={openSearch}
				anchorEl={anchorEl}
				style={{ visibility: openSearch ? 'visible' : 'hidden' }}
				onClose={handleCloseSearch}
			>
				<ClickAwayListener onClickAway={handleCloseSearch}>
					<Box pr={2}>
						<CustomizeSearchControl searchPluginInstance={searchPluginInstance} />
					</Box>
				</ClickAwayListener>
			</Popper>
		</Worker>
	);
}
