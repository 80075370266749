import React, { useRef } from 'react';

import { Box, Typography, CircularProgress } from '@mui/material';
import { colors } from '../../asset/colors';
import { Add as AddIcon } from '@mui/icons-material/';

const FileUploadDropzone = ({
	handleDragEnter,
	handleDragOver,
	handleDragLeave,
	handleDrop,
	draggedFile,
	removeFile,
	sendMultipleFiles = false,
	containerStyle,
	uploadBoxStyle,
	tooltipText
}) => {
	const fileInputRef = useRef(null);

	const handleClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = event => {
		const file = sendMultipleFiles ? event : event?.target?.files[0];
		handleDrop(file);
    fileInputRef.current.value = '';
	};

	const handleDragDrop = event => {
		event?.preventDefault();
		const file = sendMultipleFiles ? event : event?.dataTransfer?.files[0];
		handleDrop(file);
	};

	return (
		<Box
			onDragEnter={handleDragEnter}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDragDrop}
			onClick={handleClick}
			sx={containerStyle}
		>
			{draggedFile ? (
				<Box sx={styles.onUploadContainer}>
					<CircularProgress color="inherit" size={30} />
					<Typography sx={styles.fileStyle}>Uploading file: {draggedFile.name}</Typography>
				</Box>
			) : (
				<>
					<input
						type="file"
						ref={fileInputRef}
						style={{ display: 'none' }}
						onChange={handleFileChange}
						accept=".pdf,.pptx,.docx,.csv,.txt"
						multiple={sendMultipleFiles}
					/>
					<Box
						textAlign="center"
						py={2}
						title={tooltipText}
						color={colors.white}
						sx={[styles.addFileBox, uploadBoxStyle]}
						style={{
							cursor: 'pointer',
							border: '1.5px dashed #B5B5B5',
							borderWidth: 'thin',
							color: 'white',
							borderRadius: '10px',
							padding: '0.5rem',
							fontSize: '1rem'
						}}
					>
						<AddIcon sx={{ color: colors.black }} />

						<Typography color={colors.black} style={styles.newChatText}>
							Upload PDF (Drag or Click)
						</Typography>
					</Box>
				</>
			)}
		</Box>
	);
};

const styles = {
	onUploadContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	fileStyle: {
		color: colors.black,
		textAlign: 'center',
		marginTop: 2,
		fontSize: '12px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'wrap'
	},
	addFileBox: {
		backgroundColor: colors.gray_200,
		'&:hover': {
			backgroundColor: colors.gray_300
		}
	},
	uploadBtn: {
		fontSize: '10px',
		padding: '10px 28px'
	},
	newChatText: {
		fontSize: '12px',
		color: colors.black
	}
};

export default FileUploadDropzone;
