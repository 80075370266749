import { Box, CircularProgress, InputBase, Stack, Typography, IconButton, Tooltip } from '@mui/material';
// import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { colors } from '../asset/colors';
import SendIcon from '@mui/icons-material/Send';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import Popover from '@mui/material/Popover';
import { ApiContext } from '../context/ApiContext';
import { v4 as uuidv4 } from 'uuid';
import { UserSessionContext } from '../context/UserSessionContext';
import ConfirmationAlert from './SideNavDrawer/ConfirmationAlert';
import { dummyChatData } from '../constant/Constant';

const TalkToGpt = ({ onDeleteFile, handleDrawerToggle, customHeaderTitle = '', customDeleteText = '' }) => {
	const [messageText, setMessageText] = useState('');
	const {
		deleteDocument,
		postQuery,
		deleteChatHistory,
		getChatMessagesById,
		postCollectionQuery,
		getCollectionChatMessages,
		deleteCollection
	} = useContext(ApiContext);
	const { selectedFile, authenticatedUser, setSelectedChat } = useContext(UserSessionContext);
	const userID = authenticatedUser?.username;
	const [messages, setMessages] = useState([]);
	const chatContainerRef = useRef(null);
	const inputRef = useRef(null); // Create a ref for the input element
	const [isProcessingQuery, setIsProcessingQuery] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const deleteDocumentText = (
		<>
			Are you sure you want to delete this document <b>{selectedFile?.file_name}</b>?
		</>
	);
	const deleteDocumentChatText = (
		<>
			Are you sure you want to clear all the chat history of <b>{selectedFile?.file_name}</b>?
		</>
	);
	const [confirmationValues, setConfirmationValues] = useState({
		label: '',
		message: '',
		isCancelVisible: false,
		type: null
	});
	const modalType = {
		deletePdf: 'DeleteFile',
		deleteChat: 'DeleteChat'
	};

	const getAllChatData = useCallback(
		async (userId, docId, isCollectionDoc) => {
			let res;
			if (isCollectionDoc) {
				res = await getCollectionChatMessages(docId);
			} else {
				res = await getChatMessagesById(userID, docId);
			}

			const { success, response } = res;

			if (success) {
				const data = response;
				if (data.length) {
					setSelectedChat(data);
					setMessages(data);
				} else {
					setMessages(dummyChatData);
				}
			}
		},
		[getChatMessagesById, getCollectionChatMessages, setSelectedChat, userID]
	);

	useEffect(() => {
		if (userID && selectedFile) {
			getAllChatData(userID, selectedFile.id, selectedFile?.is_collection_doc);
		} else {
			setMessages(dummyChatData);
		}
	}, [getAllChatData, selectedFile, userID]);

	useEffect(() => {
		scrollChatContainerToBottom();
	}, [messages]);

	const scrollChatContainerToBottom = () => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	};

	const sendMessage = async event => {
		if (selectedFile === null) {
			return setShowAlert(true);
		}
		setIsProcessingQuery(true);
		setMessages(prev => [...prev, { id: uuidv4(), sender: 'You', message: messageText }]);
		setMessageText('');
		const payload = {
			query: messageText,
			user_id: userID,
			docs_id: selectedFile.id,
			enable_follow_up_ques: false
		};

		let res;
		if (selectedFile?.is_collection_doc) {
			payload.collection_id = selectedFile.id;

			res = await postCollectionQuery(payload);
		} else {
			payload.docs_id = selectedFile.id;

			res = await postQuery(payload);
		}

		setMessages(prev => [
			...prev,
			{
				id: uuidv4(),
				sender: 'GPT',
				message: res || 'Something went wrong, please try again later.'
			}
		]);
		inputRef.current.focus();
		setIsProcessingQuery(false);
	};

	const handleDeleteChat = async (userId, doc) => {
		await deleteChatHistory(userId, doc.id);
		await getAllChatData(userId, doc.id);
		// onDeleteFile();
	};

	const handleDeleteFile = async doc => {
		setShowConfirmation(false);
		if (doc?.is_collection_doc) {
			await deleteCollection(doc.id);
		} else {
			await deleteDocument(doc.id);
		}
		handleDeleteChat(userID, selectedFile);
		handleDrawerToggle();
		onDeleteFile();
	};

	const handleIconClick = (label, message, type, onConfirm, onCancel) => {
		console.log('here in handC');
		setConfirmationValues({ label: label, message: message, isCancelVisible: true, type: type });
		setShowConfirmation(true);
	};

	const handleConfirmation = () => {
		setShowConfirmation(false);
		switch (confirmationValues.type) {
			case modalType.deletePdf:
				handleDeleteFile(selectedFile);
				break;
			case modalType.deleteChat:
				handleDeleteChat(userID, selectedFile);
				break;
			default:
				break;
		}
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.chatHeader}>
				<Typography>{customHeaderTitle || 'Chat'}</Typography>
				<Box sx={{ ml: 'auto' }}>
					<Tooltip title="Delete PDF" placement="top" arrow>
						<IconButton
							onClick={() => handleIconClick('Delete Document', deleteDocumentText, modalType.deletePdf)}
							disabled={!selectedFile}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Reset Chat" placement="top" arrow>
						<IconButton
							onClick={() => handleIconClick('Delete Chat', deleteDocumentChatText, modalType.deleteChat)}
							disabled={!selectedFile}
						>
							<ReplayIcon />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<Box sx={styles.chatContainer} ref={chatContainerRef}>
				{messages.map((chat, index) => {
					const isUserMessage = chat.sender === 'You';
					return (
						<Stack
							key={chat.id}
							direction="row"
							sx={[styles.eachChatContainer, { flexDirection: isUserMessage ? 'row-reverse' : '' }]}
						>
							{/* <Box>
                {
                  isUserMessage ?
                  <AccountCircleRoundedIcon  style={{
                    cursor: "pointer",
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "100%",
                    background: "transparent",
                  }} />
                  : 
                  <Box style={styles.gptLogoBox}>
                    <CardMedia component="img" src={require('../asset/images/magure_icon.webp')}  alt={chat.sender} style={{
                    cursor: "pointer",
                    width: "2rem",
                    height: "2rem",
                  }} />
                  </Box>
                }
              </Box> */}

							<Typography
								sx={{
									fontSize: '0.9rem',
									wordWrap: 'break-word',
									overflowWrap: 'break-word',
									hyphens: 'auto',
									maxWidth: '80%',
									whiteSpace: 'pre-wrap',
									backgroundColor: !isUserMessage ? '#ffffff' : '#D1D1D1',
									borderRadius: !isUserMessage ? '0px 1.5rem 1.5rem 1.5rem' : '1.5rem 0 1.5rem 1.5rem',
									boxShadow: '2px 5px 25px 1px #0000000D',
									padding: '1rem'
									// marginRight:  isUserMessage ? "16px" : 0,
									// marginLeft:  !isUserMessage ? "16px" : 0
								}}
								color={'#222222'}
							>
								{chat.message && chat.message.trim()}
							</Typography>

							{isProcessingQuery && index === messages.length - 1 && (
								<CircularProgress sx={{ color: colors.black, marginRight: '10px', alignSelf: 'center' }} size={24} />
							)}
						</Stack>
					);
				})}
			</Box>

			<Box sx={styles.inputContainer}>
				<InputBase
					placeholder={isProcessingQuery ? 'Processing...' : 'Ask a question...'}
					value={messageText}
					autoFocus
					onChange={e => setMessageText(e.target.value)}
					sx={[styles.inputBox]}
					inputRef={inputRef}
					onKeyDown={event => {
						if (event.key === 'Enter' && messageText.length) {
							sendMessage();
						}
					}}
					inputProps={{
						style: {
							color: colors.black,
							fontSize: '0.9rem'
						},
						disabled: isProcessingQuery
					}}
				/>
				<IconButton onClick={sendMessage} disabled={!messageText.length} style={styles.sendButton}>
					{isProcessingQuery ? (
						<CircularProgress sx={{ color: colors.white }} size={24} />
					) : (
						<SendIcon
							sx={{
								color: colors.white
							}}
						/>
					)}
				</IconButton>
			</Box>
			<ConfirmationAlert
				open={showAlert}
				btnText="Ok"
				isCancelVisible={false}
				label={'Select Document'}
				message="Please select any pdf to start the chat"
				onConfirm={() => setShowAlert(false)}
			/>
			<ConfirmationAlert
				open={showConfirmation}
				isCancelVisible={confirmationValues.isCancelVisible}
				label={confirmationValues.label}
				message={confirmationValues.message}
				onConfirm={() => handleConfirmation()}
				onCancel={() => setShowConfirmation(false)}
			/>
		</Box>
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		backgroundColor: '#F4F4F4',
		position: 'relative'
	},
	chatContainer: {
		height: '90%',
		overflow: 'auto',
		'& > *+*': {
			/* hide scrollbar for IE, Edge and Firefox */
			msOverflowStyle: 'none'
		},
		'::-webkit-scrollbar': {
			/* hide scrollbar for chrome, safari and opera */
			display: 'none'
		},
		paddingTop: 0,
		padding: '3em 1em'
	},
	chatHeader: {
		alignItems: 'center',
		backgroundColor: colors.grayLight,
		display: 'flex',
		justifyContent: 'center',
		padding: '0.16em 0.2em 0.16em 1em',
		position: 'absolute',
		right: 0,
		left: 0,
		top: 0,
		zIndex: 2,
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
	},
	eachChatContainer: { marginY: '1rem' },
	userIndicator: {
		width: 50,
		height: 50,
		backgroundColor: colors.grayLight,
		borderRadius: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	profileCard: {
		width: 50,
		height: 50,
		backgroundColor: colors.grayLight,
		borderRadius: 2
	},
	inputContainer: {
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		padding: '0 1em',
		position: 'absolute',
		bottom: '1em',
		right: 0,
		left: 0
	},
	inputBox: {
		backgroundColor: colors.primary,
		border: 0.5,
		borderColor: colors.borderColor,
		width: '100%',
		height: '45px',
		borderRadius: 3,
		padding: '1rem'
	},
	sendButton: {
		backgroundColor: colors.black,
		borderRadius: '50%',
		marginLeft: '.8rem'
	},
	gptLogoBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '3rem',
		width: '3rem',
		background: colors.black,
		borderRadius: '50%',
		padding: '1rem'
	}
};

export default TalkToGpt;
