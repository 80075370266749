import React, { useContext, useEffect, useState } from 'react';
import styles from './NavBarListItems.module.scss';

import {
	Box,
	Card,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography
} from '@mui/material';
import {
	Info as InfoIcon,
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material';

import FileUploadDropzone from '../FileUploadDropzone';
import { UserSessionContext } from '../../../context/UserSessionContext';
import { ApiContext } from '../../../context/ApiContext';
import { colors } from '../../../asset/colors';
import CreateEditCollectionModal from '../CreateEditCollectionModal';
import CustomButton from '../../CustomButton/Custombutton';

export default function NavBarListItems({
	onListItemClick,
	documentsData,
	collectionDocsData,
	onFileUpload,
	handleCreateCollection
}) {
	const { uploadDocument, getChatMessagesById, getCollectionChatMessages } = useContext(ApiContext);
	const { authenticatedUser, selectedFile, isAdmin, setSelectedChat } = useContext(UserSessionContext);
	const userID = authenticatedUser?.username;
	const [draggedFile, setDraggedFile] = useState(null);
	const [filteredDocuments, setFilteredDocuments] = useState(documentsData);
	const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
	const [selectedCollection, setSelectedCollection] = useState();
	const [isDocListOpen, setIsDocListOpen] = useState(true);
	const [isCollectionListOpen, setIsCollectionListOpen] = useState(true);

	useEffect(() => {
		setFilteredDocuments(documentsData);
	}, [documentsData]);

	const handleDrop = async file => {
		if (file) {
			setDraggedFile(file);
			console.log('Start uploading the file');
			await uploadDocument(userID, file);
			onFileUpload();
			setDraggedFile(null);
		}
	};

	const getMessagesbyId = async (docId, isCollectionDoc) => {
		let res;
		if (isCollectionDoc) {
			res = await getCollectionChatMessages(docId);
		} else {
			res = await getChatMessagesById(userID, docId);
		}
		setSelectedChat(res);
	};

	const renderDocumentLists = (item, i) => {
		const isFileSelected = selectedFile?.id === item.id;
		const docTitle = item?.is_collection_doc ? item.collection_name : item.file_name;

		return (
			<ListItem key={i} sx={{ bgcolor: isFileSelected ? colors.gray_200 : '', px: 0 }} className={styles.listItem}>
				<ListItemButton
					onClick={() => {
						onListItemClick(item, true);
						getMessagesbyId(item.id, item?.is_collection_doc);
					}}
				>
					<ListItemText
						primaryTypographyProps={{
							style: {
								fontSize: '14px',
								fontWeight: isFileSelected ? 600 : 200,
								whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
								overflow: 'hidden', // Hide overflowed text
								textOverflow: 'ellipsis' // Display ellipsis (...) for overflowed text
							}
						}}
						primary={docTitle}
					/>

					{item?.is_collection_doc && (
						<IconButton
							onClick={e => {
								if (e && e.stopPropagation) e.stopPropagation(); // This will prevent parent btn click when pin icon is clicked

								setIsCollectionModalOpen(true);
								setSelectedCollection(item);
							}}
						>
							<InfoIcon fontSize="small" sx={{ color: colors.black }} />
						</IconButton>
					)}
				</ListItemButton>
			</ListItem>
		);
	};

	return (
		<Box sx={sxStyles.navBarContainer}>
			<List disablePadding>
				{/* Add Document */}
				{isAdmin && (
					<FileUploadDropzone
						handleDragEnter={e => e.preventDefault()}
						handleDragOver={e => e.preventDefault()}
						handleDragLeave={e => e.preventDefault()}
						handleDrop={handleDrop}
						draggedFile={draggedFile}
						removeFile={() => setDraggedFile(null)}
						tooltipText={'Chat with a single document'}
					/>
				)}

				<CustomButton
					title="Create Collection"
					style={sxStyles.createCollectionBtn}
					onClick={handleCreateCollection}
					tooltipText={'Chat with multiple documents'}
				/>

				<Box sx={sxStyles.shadowDivider} />

				{/* Document list */}
				{filteredDocuments?.length === 0 && collectionDocsData?.length === 0 ? (
					<Typography textAlign="center" fontSize={14} color={colors.black} mt={5}>
						No document found
					</Typography>
				) : (
					<List sx={sxStyles.docListContainer}>
						{filteredDocuments?.length > 0 && (
							<Card sx={sxStyles.docListCard}>
								<ListItemButton onClick={() => setIsDocListOpen(prev => !prev)}>
									<ListItemText primary="Documents" />
									{isDocListOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								</ListItemButton>

								<Collapse in={isDocListOpen} timeout="auto">
									{filteredDocuments?.map((item, i) => renderDocumentLists(item, i))}
								</Collapse>
							</Card>
						)}

						{collectionDocsData?.length > 0 && (
							<Card sx={[sxStyles.docListCard, { mt: 2 }]}>
								<ListItemButton onClick={() => setIsCollectionListOpen(prev => !prev)}>
									<ListItemText primary="Collections" />

									{isCollectionListOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								</ListItemButton>

								<Collapse in={isCollectionListOpen} timeout="auto">
									{collectionDocsData?.map((item, i) => renderDocumentLists(item, i))}
								</Collapse>
							</Card>
						)}
					</List>
				)}
			</List>

			<CreateEditCollectionModal
				open={isCollectionModalOpen}
				onClose={() => {
					setIsCollectionModalOpen(false);
					setSelectedCollection({});
				}}
				// handleSubmit={getAllDocData}
				isEdit={true}
				selectedCollection={selectedCollection}
			/>
		</Box>
	);
}

const sxStyles = {
	navBarContainer: {
		overflow: 'auto',
		'& > *+*': {
			/* hide scrollbar for IE, Edge and Firefox */
			msOverflowStyle: 'none'
		},
		'::-webkit-scrollbar': {
			/* hide scrollbar for chrome, safari and opera */
			display: 'none'
		}
	},
	docListContainer: {
		mt: 2,
		pt: 0,
		'& ul': { padding: 0 }
	},
	shadowDivider: {
		height: '2px',
		width: '100%',
		background: 'linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(214,214,214,1) 50%, rgba(238,238,238,1) 100%)',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Soft shadow
		marginBottom: '20px'
	},
	docListCard: {
		borderRadius: '10px',
		mx: 0.5
	},
	createCollectionBtn: {
		fontSize: '12px',
		width: '100%',
		marginTop: '20px',
		marginBottom: '20px'
	}
};
