import React from "react";
import styles from "./NavHeader.module.scss";
import { DrawerHeader } from "../../../constant/Constant";
import { Box, CardMedia, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function SideNavHeader({ open, handleDrawerToggle }) {

  return (
    <DrawerHeader
      open={open}
      className={styles.container}
    >
      <Box sx={{ display: 'flex', borderRadius: '50%', alignItems: 'center' }}>
        <CardMedia component="img" src={require('../../../asset/images/magure_icon.webp')} alt="logo" style={{
          cursor: "pointer",
          width: "30px",
          height: "30px",
        }} />
        <Typography
          className={styles.title}
        >
          Chat with Docs!
        </Typography>
      </Box>
      <IconButton onClick={handleDrawerToggle} sx={{ boxShadow: 3, width: '30px', height: '30px' }}>
        <ChevronLeftIcon />
      </IconButton>
    </DrawerHeader>
  );
}
