import { createContext, useCallback, useContext } from 'react';
import { UserSessionContext } from './UserSessionContext';
import { Auth } from 'aws-amplify';

export const AmplifyAuthContext = createContext();

const AmplifyAuthContextProvider = ({ children }) => {
	const { setShowLoader, setAuthenticatedUser, setCognitoUser } = useContext(UserSessionContext);

	const loginUser = useCallback(
		async (email, password) => {
			setShowLoader(true);
			try {
				const response = await Auth.signIn(email, password);

				return { success: true, errorMsg: '', response };
			} catch (e) {
				return { success: false, errorMsg: e.message, response: {} };
			} finally {
				setShowLoader(false);
			}
		},
		[setShowLoader]
	);

	const signUpUser = useCallback(
		async (email, password) => {
			setShowLoader(true);
			try {
				const response = await Auth.signUp({ username: email, password: password });
				setCognitoUser({
					cogId: response?.userSub,
					email: response?.user?.username
				});
				return { success: true, errorMsg: '', response };
			} catch (e) {
				return { success: false, errorMsg: e.message, response: {} };
			} finally {
				setShowLoader(false);
			}
		},
		[setCognitoUser, setShowLoader]
	);

	const confirmSignup = useCallback(
		async (username, code) => {
			setShowLoader(true);
			try {
				await Auth.confirmSignUp(username, code);
				
				return { success: true, errorMsg: '', response: {} };
			} catch (e) {
				return { success: false, errorMsg: e.message, response: {} };
			} finally {
				setShowLoader(false);
			}
		},
		[setShowLoader]
	);

	const signOutUser = useCallback(
		async user => {
			setShowLoader(true);
			try {
				await Auth.signOut();
				setAuthenticatedUser(null);

				return { success: true, errorMsg: '', response: {} };
			} catch (e) {
				console.log('Exception', e);
				return { success: false, errorMsg: e.message, response: {} };
			} finally {
				setShowLoader(false);
			}
		},
		[setAuthenticatedUser, setShowLoader]
	);

	const getCurrentAuthenticatedUser = useCallback(async () => {
		try {
			const res = await Auth.currentAuthenticatedUser();
			console.log('res: ', res);
			setAuthenticatedUser(res);
		} catch (error) {
			console.log('Exception', error);
		} finally {
			setShowLoader(false);
		}
	}, [setAuthenticatedUser, setShowLoader]);

	return (
		<AmplifyAuthContext.Provider
			value={{
				loginUser,
				signUpUser,
				confirmSignup,
				signOutUser,
				getCurrentAuthenticatedUser
			}}
		>
			{children}
		</AmplifyAuthContext.Provider>
	);
};

export default AmplifyAuthContextProvider;
