import React, { useState } from 'react';
import { MinimalButton, Position, Tooltip } from '@react-pdf-viewer/core';
import { NextIcon, PreviousIcon } from '@react-pdf-viewer/search';
import style from './CustomizeSearchControl.module.css';
import { Close } from '@mui/icons-material';

import matchWholeWordIcon from '../../asset/whole-word.png';
import fontCase from '../../asset/font-case.png';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

const CustomizeSearchControl = ({ searchPluginInstance }) => {
	const [readyToSearch, setReadyToSearch] = useState(false);
	const { Search } = searchPluginInstance;

	return (
		<div className={style.container}>
			<div className={style.header}>
				<Search>
					{renderSearchProps => {
						return (
							<>
								<div className={style.inputContainer}>
									<input
										className={style.inputField}
										autoFocus
										placeholder="Enter to search"
										type="text"
										value={renderSearchProps.keyword}
										onChange={e => {
											setReadyToSearch(false);
											renderSearchProps.setKeyword(e.target.value);
										}}
										onKeyDown={e => {
											if (e.keyCode === 13 && renderSearchProps.keyword) {
												setReadyToSearch(true);
												renderSearchProps.search();
											}
										}}
									/>
									<Tooltip
										position={Position.BottomCenter}
										target={
											<button className={style.clearButton} onClick={() => renderSearchProps.clearKeyword()}>
												<Close
													style={{
														background: '#fff',
														height: '16px',
														visibility: renderSearchProps.keyword ? 'visible' : 'hidden',
														verticalAlign: 'middle',
														borderRadius: '10px'
													}}
												/>
											</button>
										}
										content={() => 'Clear Input'}
										offset={{ left: 0, top: renderSearchProps.keyword ? 8 : -999 }}
									/>
								</div>
								{readyToSearch && renderSearchProps.keyword && renderSearchProps.numberOfMatches === 0 && (
									<div className={style.pageNumberContainer}>0 / 0</div>
								)}
								{readyToSearch && renderSearchProps.keyword && renderSearchProps.numberOfMatches > 0 && (
									<div className={style.pageNumberContainer}>
										{renderSearchProps.currentMatch} of {renderSearchProps.numberOfMatches}
									</div>
								)}
								<div className={style.toolTipContainer}>
									<Tooltip
										position={Position.BottomCenter}
										target={
											<button
												className={style.matchCase}
												style={{ borderBottom: `2px solid ${renderSearchProps.matchCase ? 'blue' : 'transparent'}` }}
												onClick={() => renderSearchProps.changeMatchCase(!renderSearchProps.matchCase)}
											>
												<img
													src={fontCase}
													width={20}
													height={20}
													alt="Match Whole Word"
													style={{ verticalAlign: 'middle' }}
												/>
											</button>
										}
										content={() => 'Match case'}
										offset={{ left: 0, top: 8 }}
									/>
								</div>
								<div className={style.toolTipContainer}>
									<Tooltip
										position={Position.BottomCenter}
										style={{}}
										target={
											<button
												className={style.matchWholeWord}
												style={{
													borderBottom: `2px solid ${renderSearchProps.wholeWords ? 'blue' : 'transparent'}`
												}}
												onClick={() => renderSearchProps.changeWholeWords(!renderSearchProps.wholeWords)}
											>
												<img
													src={matchWholeWordIcon}
													width={20}
													height={20}
													alt="Match Whole Word"
													style={{ verticalAlign: 'middle' }}
												/>
											</button>
										}
										content={() => 'Match whole word'}
										offset={{ left: 0, top: 8 }}
									/>
								</div>
								<div className={style.toolTipContainer}>
									<Tooltip
										position={Position.BottomCenter}
										target={
											<MinimalButton onClick={renderSearchProps.jumpToPreviousMatch}>
												<PreviousIcon />
											</MinimalButton>
										}
										content={() => 'Previous match'}
										offset={{ left: 0, top: 8 }}
									/>
								</div>
								<div className={style.toolTipContainer}>
									<Tooltip
										position={Position.BottomCenter}
										target={
											<MinimalButton onClick={renderSearchProps.jumpToNextMatch}>
												<NextIcon />
											</MinimalButton>
										}
										content={() => 'Next match'}
										offset={{ left: 0, top: 8 }}
									/>
								</div>
							</>
						);
					}}
				</Search>
			</div>
		</div>
	);
};

export default CustomizeSearchControl;
