import React from 'react';
import styles from './SignupScreenWrapper.module.css';
import logoImage from '../../asset/images/magure_icon.webp';

export default function SignupScreenWrapper({ children }) {

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={logoImage} alt="Logo" className={styles.logo} />
        <p className={styles.text}>Chat with Docs!</p>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}
