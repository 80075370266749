// SuccessPayment.js
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import styles from './PaymentStatus.module.scss';
import { CustomButton } from '../../components';
import ErrorIcon from '@mui/icons-material/Error';

export default function PaymentStatus() {
	const navigate = useNavigate();
	const [countdown, setCountdown] = useState(10);
	const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (countdown === 0) {
				navigate('/');
			} else {
				setCountdown(prevCountdown => prevCountdown - 1);
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [countdown, navigate]);

	return (
		<div className={styles.container}>
			<div className={styles.paymentContent}>
				<div>
					{isPaymentSuccess ? (
						<CheckCircleIcon style={{ color: '#4caf50' }} className={styles.icons} fontSize="large" />
					) : (
						<ErrorIcon style={{ color: '#fc0330' }} className={styles.icons} fontSize="large" />
					)}
				</div>
				<Typography variant="h4" gutterBottom mt={2}>
					Payment {isPaymentSuccess ? ' Successful' : ' Failed'}
				</Typography>
				<Typography variant="body1" mt={1}>
					{isPaymentSuccess
						? 'Thank you for your purchase. Your payment was successful.'
						: 'oops! something went wrong. Your payment was unsuccessful.'}
				</Typography>
				<Typography variant="body2" mt={1} mb={3}>
					You will be redirected to the homepage in {countdown} seconds.
				</Typography>
				<CustomButton title={'Return to Homepage'} style={{ fontWeight: 'normal' }} onClick={() => navigate('/')} />
			</div>
		</div>
	);
}
