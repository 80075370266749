import React, { useContext, useState } from 'react';
import { CustomButton, CustomInput, SignupScreenWrapper } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import styles from './OtpScreen.module.css';
import ErrorAlert from '../../components/ErrorAlert';
import { UserSessionContext } from '../../context/UserSessionContext';
import { ApiContext } from '../../context/ApiContext';

export default function OtpScreen() {
  const { getCurrentAuthenticatedUser } = useContext(AmplifyAuthContext);
  const { cognitoUser } = useContext(UserSessionContext);
	const { createUserWithCognitoId } = useContext(ApiContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { email, otpDeliveryDestination } = location.state;
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleShowError = (message) => {
    setErrorMessage(message);
    setErrorOpen(true);
  };

  const handleCloseError = () => {
    setErrorOpen(false);
  };

  const handleConfirm = async (event) => {
    event.preventDefault();

    const { otp } = event.target.elements;

    try {
      await Auth.confirmSignUp(email, otp.value);
    
      await createUserWithCognitoId(cognitoUser);

      await getCurrentAuthenticatedUser();

      navigate('/login', { replace: true });
    } catch (error) {
      handleShowError('An error occurred');
    } 
  };

  return (
    <SignupScreenWrapper>
 
      <div>
        <h2>Verification</h2>

        <h5 style={{ marginBottom: 20 }}>Code sent to {otpDeliveryDestination}</h5>

        <form onSubmit={handleConfirm}>
          <CustomInput inputTitle="OTP" name="otp" type="text" className={styles.formItems} />

		  <div className={styles.paddingDiv}></div>
          <CustomButton title="Confirm" style={{ width: '100%' }} />
        </form>
      </div>
      <ErrorAlert open={errorOpen} message={errorMessage} onClose={handleCloseError} />
    </SignupScreenWrapper>
  );
}
