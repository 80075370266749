import { Box, CardMedia, IconButton, Toolbar } from "@mui/material";
import styles from "./MinimizedToolBar.module.scss";
import React from "react";
import {colors} from "../../../asset/colors";

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";

export const MinimisedToolBar = ({
    handleDrawerToggle,
    onLogout
}) => {
    
    return (
        <Toolbar sx={{ display: 'flex'}} className={styles.toolbar}>
            <Box style={{marginBottom: "1rem", alignItems: 'center', display: 'flex' }}>
                <CardMedia component="img" src={require('../../../asset/images/magure_icon.webp')}  alt="logo" style={{
                    cursor: "pointer",
                    width: '30px',
                    height: "30px"
                }} />
            </Box>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleDrawerToggle()}
                sx={{ m: 0,  }}
            >
                <MenuIcon color={colors.black} className={styles.menuIcon} />
            </IconButton>
            <IconButton sx={{boxShadow: 1}} className={styles.sideLogout} onClick={() => onLogout()} >
                <PowerSettingsNew
                    className={styles.logoutButton}
                />
            </IconButton>
        </Toolbar>
    );
};