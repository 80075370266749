import { createContext, useState } from 'react';

export const UserSessionContext = createContext();

const UserSessionContextProvider = ({ children }) => {
	const [showLoader, setShowLoader] = useState(true);
	const [selectedFile, setSelectedFile] = useState(null);

	const [authenticatedUser, setAuthenticatedUser] = useState(null);
	const [cognitoUser, setCognitoUser] = useState(null);

	const [selectedChat, setSelectedChat] = useState([]);

	// const isAdmin = authenticatedUser?.attributes?.email === 'praduman@magureinc.com' || true;
	const isAdmin = true;

	return (
		<UserSessionContext.Provider
			value={{
				showLoader,
				setShowLoader,
				authenticatedUser,
				setAuthenticatedUser,
				cognitoUser,
				setCognitoUser,
				selectedChat,
				setSelectedChat,
				selectedFile,
				setSelectedFile,
				isAdmin
			}}
		>
			{children}
		</UserSessionContext.Provider>
	);
};

export default UserSessionContextProvider;
