import React from 'react';
import { TextField } from '@material-ui/core';
import { Box, InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';


function CustomInput({
  containerStyle,
  type,
  name,
  onChange,
  placeholder,
  value,
  inputStyle,
  disabled,
  className,
  inputTitle,
  variant='outlined',
  isSearchIconVisible=false,
  required = false
}) {
  return (
    <Box style={containerStyle} className={className}>
      <TextField
        variant={variant}
        required={required}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        label={inputTitle}
        style={{ ...inputStyle, width: '100%' }}
        disabled={disabled}
        value={value}
        InputProps={{
          style: { fontSize: '16px', height: isSearchIconVisible ? '40px' : 'auto' },
          startAdornment: isSearchIconVisible && (
            <InputAdornment position="start">
              <SearchIcon style={{ height: '18px' }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default CustomInput;
