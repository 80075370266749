import { createContext, useCallback, useContext } from 'react';
import axios from 'axios';
import { UserSessionContext } from './UserSessionContext';
import { parseCollectionData, parseCollectionDoc, parseGetAllChats } from '../utils/dataParser';

const AxiosService = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

export const ApiContext = createContext();

const ApiContextProvider = ({ children }) => {
	const { setShowLoader } = useContext(UserSessionContext);

	const get = useCallback(
		async (endpoint, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.get(endpoint)
				.then(res => {
					console.log(`GET: ${endpoint} res`, res.status);

					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					console.log(`GET - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	const post = useCallback(
		async (endpoint, data, headers, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.post(endpoint, data, headers)
				.then(res => {
					console.log(`POST: ${endpoint} res`, res.status);
					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					console.log(`POST - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	const deleteDoc = useCallback(
		async (endpoint, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.delete(endpoint)
				.then(res => {
					console.log(`DELETE: ${endpoint} res`, res.status);

					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					console.log(`DELETE - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => {
					getAllCollectionDocuments();
					getAllDocument();
					setShowLoader(false);
				});
		},
		[setShowLoader]
	);

	// method to take file and post it to the backend
	const postFile = useCallback(
		async (endpoint, file, userId, showLoader = true) => {
			setShowLoader(showLoader);
			const formData = new FormData();
			formData.append('file', file);
			formData.append('user_id', userId);
			formData.append('is_docs_formatted', false);
			return AxiosService.post(endpoint, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(res => {
					console.log(`POST: ${endpoint} res`, res.status);
				})
				.catch(err => {
					console.log(`POST - ${endpoint} err`, err);
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);
	// end of base methods

	//below are the alpthabetical order of APIs

	const createUserWithCognitoId = useCallback(
		async cognitoUser => {
			const req = {
				user_id: cognitoUser.cogId,
				user_email_id: cognitoUser.email
			};
			const res = await post(`/user/`, req);

			return res;
		},
		[post]
	);

	const deleteDocument = useCallback(
		async id => {
			const res = await deleteDoc(`/docs/${id}/`);

			return res;
		},
		[deleteDoc]
	);
	const deleteCollection = useCallback(
		async id => {
			const res = await deleteDoc(`/collection/${id}/`);

			return res;
		},
		[deleteDoc]
	);

	const deleteChatHistory = useCallback(
		async (userId, docId) => {
			const res = await deleteDoc(`/message/?user_id=${userId}&docs_id=${docId}`);

			return res;
		},
		[deleteDoc]
	);

	const getDocumentByUserId = useCallback(
		async (userId, showLaoder = false) => {
			const res = await get(`docs/?user_id=${userId}`, showLaoder);

			return res;
		},
		[get]
	);

	const getAllDocument = useCallback(async () => {
		const res = await get(`/docs/`);

		return res;
	}, [get]);

	const getChatMessagesById = useCallback(
		async (userId, docId) => {
			const res = await get(`/message/?user_id=${userId}&docs_id=${docId}`);
			return parseGetAllChats(res && res?.response && res?.response?.response_data);
		},
		[get]
	);

	const postQuery = useCallback(
		async (query, headers = {}, showLoader = false) => {
			const res = await post(`/ask_from_docs/`, query, headers, showLoader);
			return res && res.response && res.response.response_data && res.response.response_data.answer;
		},
		[post]
	);

	const uploadDocument = useCallback(
		async (userId, file, showLoader = false) => {
			const res = await postFile(`/docs/`, file, userId, showLoader);

			return res;
		},
		[postFile]
	);

	// Collection endpoints
	const postCollectionQuery = useCallback(
		async (query, headers = {}, showLoader = false) => {
			const res = await post(`/ask_from_collection/`, query, headers, showLoader);
			return res?.response?.response_data?.answer;
		},
		[post]
	);

	const createCollection = useCallback(
		async (userId, collectionName, files) => {
			const formData = new FormData();

			formData.append('user_id', userId);
			formData.append('collection_name', collectionName);

			files.forEach(eachFile => formData.append('file', eachFile));

			const res = await post(`/collection/`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json'
				}
			});

			return res;
		},
		[post]
	);

	const getAllCollectionByUserId = useCallback(
		async (userId, showLoader = false) => {
			const res = await get(`/collection/?user_id=${userId}`, showLoader);

			if (res?.response?.response_data) {
				const parsedCollection = parseCollectionData(res?.response?.response_data);

				return { ...res, response: parsedCollection };
			}

			return [];
		},
		[get]
	);

	const getCollectionChatMessages = useCallback(
		async collectionId => {
			const res = await get(`/message/?collection_id=${collectionId}`);
			return parseGetAllChats(res?.response?.response_data);
		},
		[get]
	);

	const getAllCollectionDocuments = useCallback(
		async collectionId => {
			const res = await get(`/collection_docs/?collection_id=${collectionId}`);

			if (res?.response?.response_data) {
				const parsedRes = parseCollectionDoc(res?.response?.response_data);

				return { ...res, response: parsedRes };
			}

			return res;
		},
		[get]
	);

	const addDocsToCollection = useCallback(
		async (userId, collectionId, files) => {
			const formData = new FormData();

			formData.append('user_id', userId);
			formData.append('collection_id', collectionId);

			files.forEach(eachFile => formData.append('file', eachFile));

			const res = post(`/collection_docs/`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: 'application/json'
				}
			});

			return res;
		},
		[post]
	);

	const removeDocFromCollection = useCallback(
		async collectionDocId => {
			const res = await deleteDoc(`collection_docs/${collectionDocId}/`);

			return res;
		},
		[deleteDoc]
	);

	return (
		<ApiContext.Provider
			value={{
				createUserWithCognitoId,
				deleteDocument,
				deleteCollection,
				deleteChatHistory,
				getAllDocument,
				getDocumentByUserId,
				getChatMessagesById,
				postQuery,
				uploadDocument,
				// Collection endpoints
				createCollection,
				getAllCollectionByUserId,
				postCollectionQuery,
				getCollectionChatMessages,
				getAllCollectionDocuments,
				addDocsToCollection,
				removeDocFromCollection
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiContextProvider;
